import { UnlockCircle } from '@bb-ui/icons/dist/small';
import { DefaultButton } from '@bb-ui/react-library';
import { ReleaseStageUnlockAllButtonDialog } from 'dialogs/ReleaseStageUnlockAllButtonDialog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ReleaseStageUnlockAllButtonProps {
  releaseStageId: string;
  releaseStageName: string;
  onSuccess: () => void;
}

export const ReleaseStageUnlockAllButton: React.FunctionComponent<
  ReleaseStageUnlockAllButtonProps
> = ({ releaseStageId, releaseStageName, onSuccess }) => {
  const { t } = useTranslation();
  const [releaseStageUnlockAllButtonDialogOpen, setReleaseStageUnlockAllButtonDialogOpen] =
    React.useState(false);
  return (
    <>
      <DefaultButton
        startIcon={<UnlockCircle />}
        data-testid="release-stage-unlock-all-button"
        onClick={() => setReleaseStageUnlockAllButtonDialogOpen(true)}
      >
        {t('releaseStages.releaseStageDrawer.unlockButtonTitle')}
      </DefaultButton>
      <ReleaseStageUnlockAllButtonDialog
        id="unlock-all-release-stage-dialog"
        onClose={() => setReleaseStageUnlockAllButtonDialogOpen(false)}
        releaseStageId={releaseStageId}
        releaseStageName={releaseStageName}
        open={releaseStageUnlockAllButtonDialogOpen}
        onSuccess={() => {
          setReleaseStageUnlockAllButtonDialogOpen(false);
          onSuccess();
        }}
      />
    </>
  );
};
