import * as React from 'react';
import { Trash } from '@bb-ui/icons/dist/small';
import { useTranslation } from 'react-i18next';
import { makeStyles, OutlineButton } from '@bb-ui/react-library';
import { RemoveImagesDialog } from '../../dialogs/RemoveImagesDialog';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
}));

export interface RemoveImagesButtonProps {
  onReload: () => void;
  amiId: string;
  version: string;
}

export const RemoveImagesButton: React.FunctionComponent<RemoveImagesButtonProps> = ({
  onReload,
  amiId,
  version,
}) => {
  const { t } = useTranslation();
  const [removeButtonDialogOpen, setRemoveButtonDialogOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <OutlineButton
        startIcon={<Trash />}
        data-testid="remove-button-images"
        className={classes.button}
        onClick={() => setRemoveButtonDialogOpen(true)}
      >
        {t('images.imageRegistryRemoveButton')}
      </OutlineButton>
      <RemoveImagesDialog
        id="remove-images-dialog"
        onClose={() => setRemoveButtonDialogOpen(false)}
        onSuccess={onReload}
        open={removeButtonDialogOpen}
        amiId={amiId}
        version={version}
      />
    </>
  );
};
