import * as React from 'react';
import {
  DefaultButton,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormLabel,
  PrimaryButton,
  Radio,
  RadioGroup,
  createStyles,
  makeStyles,
  Theme,
} from '@bb-ui/react-library';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import useRestApi from 'hooks/useRestApi';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButton: {
      marginLeft: theme.spacing(-1),
    },
  }),
);

export interface EditImageDialogProps extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  amiId: string;
  version: string;
  approved: boolean;
  createdAt: number;
}

export const EditImagesDialog: React.FunctionComponent<EditImageDialogProps> = (props) => {
  const classes = useStyles(props);
  const { id, open, onClose, onSuccess, amiId, version, approved, createdAt } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPatch,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('lct', `registry/${amiId}`), { manual: true });
  const [showErrors, setShowErrors] = React.useState(false);
  const [approvalChoice, setApprovalData] = React.useState<boolean>(approved);

  function handleApprovalChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value === 'yes';
    setApprovalData(value);
  }

  function edit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowErrors(true);
    doPatch(version, {
      amiId: amiId,
      version: version,
      approved: approvalChoice,
      approvalDate: Intl.DateTimeFormat('en-US').format(new Date()),
      createdAt: createdAt,
    });
  }

  const close = React.useCallback(() => {
    setShowErrors(false);
    onClose();
  }, [onClose]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('images.editSuccess'), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
      onSuccess();
    }
  }, [
    clearSucceededRequests,
    close,
    enqueueSnackbar,
    approvalChoice,
    onSuccess,
    succeededRequests.length,
    t,
  ]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('images.editFailed', {
          message: failedRequests[0].error.message,
          amiId: amiId,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [
    clearFailedRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    approvalChoice,
    t,
    amiId,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={close} id={`${id}-title`}>
        {t('images.editImageTitle')}
      </DialogTitle>
      <form onSubmit={edit} noValidate data-testid="edit-images-form">
        <DialogContent>
          <FormLabel>{t('images.imageApproveConfirmation')}</FormLabel>
          <RadioGroup
            hasCustomLegend
            id="image-registry-approval-options"
            name="image-approval-status"
            onChange={handleApprovalChange}
          >
            <FormControlLabel
              className={classes.radioButton}
              checked={approvalChoice === true}
              value="yes"
              control={<Radio />}
              label={t('global.yes')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={approvalChoice === false}
              value="no"
              control={<Radio />}
              label={t('global.no')}
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid="cancel-edit-images-dialog" onClick={close}>
            {t('global.cancel')}
          </DefaultButton>
          <PrimaryButton
            data-testid="submit-edit-images-dialog"
            disabled={loadingRequests || approvalChoice === approved}
            type="submit"
          >
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditImagesDialog;
