// code for the toggle switch both in parent and descendant components
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import { Tooltip } from '@bb-ui/react-library/dist/components/Tooltip';
import { Switch } from '@bb-ui/react-library/dist/components/Switch';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { ToggleStatusString } from './MobileConstants';
import { MobileConfigurationFields } from './useTenantMobile';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      maxWidth: 125,
      justifyContent: 'center',
    },
    switchLabelContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
    },
    switchLabel: {
      minWidth: 30,
    },
    switchTitle: {
      minWidth: 30,
    },
  }),
);
interface ToggleProps {
  isFromChildren: boolean;
  mobileProps?: MobileConfigurationFields;
  updateMobileDetails: (toggleValue: string, isModified: boolean) => void;
  updateToggleValue?: (newToggleValue: string) => void;
}

export const Toggle: React.FC<ToggleProps> = ({
  isFromChildren,
  mobileProps,
  updateMobileDetails,
  updateToggleValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Check if data is available from the parent component
    if (!isFromChildren && mobileProps) {
      setIsActive(mobileProps.forceOff === '1');
    }
    if (isFromChildren && mobileProps) {
      setIsActive(mobileProps.authType === 'FORCE_TO_WEB');
    }
  }, [mobileProps, isFromChildren]);

  const getSupportingText = (isFromChildren: boolean, isActive: boolean) => {
    if (isFromChildren) {
      return isActive
        ? t('mobileConfiguration.toggleType.fwl.tooltipMessage.inactive')
        : t('mobileConfiguration.toggleType.fwl.tooltipMessage.active');
    }
    return isActive
      ? t('mobileConfiguration.toggleType.forcedOff.tooltipMessage.inactive')
      : t('mobileConfiguration.toggleType.forcedOff.tooltipMessage.active');
  };

  // Toggle the state of the current row
  const handleIsActiveChange = () => {
    const toggleIsActiveValue = !isActive;
    setIsActive(toggleIsActiveValue); // Toggle the state of the current row
    const toggleValue = toggleIsActiveValue ? ToggleStatusString.On : ToggleStatusString.Off;
    if (!isFromChildren && mobileProps) {
      updateMobileDetails(toggleValue, true);
    } else if (isFromChildren && mobileProps) {
      updateMobileDetails(toggleValue, false);
    } else if (isFromChildren && updateToggleValue) {
      updateToggleValue(toggleValue);
    }
  };
  return (
    <>
      <div className={classes.switchLabelContainer}>
        <FormControlLabel
          control={
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={getSupportingText(isFromChildren, isActive)}
            >
              <Switch
                data-testid={isFromChildren ? 'fwl-toggle-switch' : 'forced-off-toggle-switch'}
                onChange={handleIsActiveChange}
                checked={isActive}
              />
            </Tooltip>
          }
          label={
            <div
              className={classes.switchLabel}
              data-testid={isFromChildren ? 'fwl-toggle-text' : 'forced-off-toggle-text'}
            >
              {isActive
                ? t('mobileConfiguration.toggleStatus.on')
                : t('mobileConfiguration.toggleStatus.off')}
            </div>
          }
        />
        <div className={classes.switchTitle}>
          {isFromChildren
            ? t('mobileConfiguration.toggleType.fwl.label')
            : t('mobileConfiguration.toggleType.forcedOff.label')}
        </div>
      </div>
    </>
  );
};
