import React, { ReactElement, useEffect } from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  makeStyles,
  createStyles,
  CircularProgress,
  CardHeader,
} from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { UseRestApiResult } from 'hooks/useRestApi.types';
import { DdaIpsData } from 'App.types';
import { useParams } from 'react-router';
import { Typography } from '@material-ui/core';

interface DdaIpsResp extends Omit<UseRestApiResult, 'data'> {
  data: {
    items: DdaIpsData[];
  };
}

export const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      marginBottom: '14px',
    },
    section: {
      marginTop: '24px',
    },
    table: {
      width: '50%',
    },
  })
);

export const DdaDefinitionsIpLists: React.FC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { tenantId } = useParams<{ tenantId: string }>();
  const {
    data: ddaIps,
    error,
    loading,
    fetch,
  } = useRestApi(apiUrl('lct', `dda/${tenantId}/ips`)) as DdaIpsResp;
  let content: ReactElement = <></>;

  useEffect(() => {
    fetch();
  }, [fetch]);

  const loader = () => (
    <CircularProgress
      ariaLabel={t('global.loadingIndicator.loading').toString()}
      data-testid="loading-dda-ips"
    />
  );

  const erroMessage = () => (
    <Typography data-testid="dda-ip-info-error">{t('global.fetchError')}</Typography>
  );

  const noDdaIpMessage = () =>
    (content = <Typography data-testid="dda-ip-list-no-data">{t('ddaIpList.noData')}</Typography>);

  const ipTable = (ddaIps: DdaIpsData[]) => (
    <Table className={classes.table} data-testid={'dda-ips-datatable'}>
      <TableHead>
        <TableRow>
          <TableCell>{t('ddaIpList.id')}</TableCell>
          <TableCell align="justify">{t('ddaIpList.ipNumber')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ddaIps.map((ip: DdaIpsData) => {
          return (
            <TableRow key={ip.id}>
              <TableCell>{ip.id}</TableCell>
              <TableCell align="justify">{ip.ipAddress}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  if (error) {
    content = erroMessage();
  } else if (loading) {
    content = loader();
  } else if (!ddaIps?.items.length) {
    content = noDdaIpMessage();
  } else {
    content = ipTable(ddaIps.items);
  }

  return (
    <div data-testid="dda-ip-list-section" className={classes.section}>
      <CardHeader
        title={t('ddaIpList.pageTitle')}
        titleTypographyProps={{
          color: 'textSecondary',
          component: 'h3' as any,
          variant: 'h4',
        }}
        className={classes.heading}
      />
      {content}
    </div>
  );
};
