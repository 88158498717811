import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@bb-ui/react-library';
import { Combobox, ComboboxOption } from '@bb-ui/react-library/dist/components/Combobox';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useTenantContext } from 'contexts/TenantContext';
import useRestApi from 'hooks/useRestApi';
import { Item, ResponseData } from 'pages/ReleaseStages/ReleaseStages.types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';
import { BindReleaseStageConfigurationDialog } from 'dialogs/BindReleaseStageConfigurationDialog';
import { UnbindReleaseStageConfigurationButton } from './UnbindReleaseStageConfigurationButton';

export const useStyles = makeStyles(() =>
  createStyles({
    section: {
      marginBottom: '45px',
    },
    combobox: {
      display: 'flex',
      flexWrap: 'wrap',
      gridGap: '20px',
      flexDirection: 'column',
      float: 'right',
      marginBottom: '20px',
    },
  }),
);

export const ReleaseStageConfiguration: React.FunctionComponent = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { tenantId, tenant } = useTenantContext();
  const [releaseStageConfigDialog, setReleaseStageConfigDialog] = React.useState(false);
  const [selectedReleaseStageId, setSelectedReleaseStageId] = React.useState('');
  const [selectedReleaseStageName, setSelectedReleaseStageName] = React.useState('');
  const emptyComboBox = { value: '', label: 'Choose One' };
  const [comboBoxValue, setComboBoxValue] = React.useState<ComboboxOption>(emptyComboBox);
  const { data: releaseStageData, fetch: releaseStageFetching } = useRestApi<ResponseData>(
    apiUrl('lct', `releaseStage/?excludeTenantId=${tenantId}`),
  );

  const {
    data: releaseStageByTenantData,
    error: releaseStageBytenantError,
    loading: releaseStageByTenantLoading,
    fetch: releaseStageByTenantFetching,
  } = useRestApi(apiUrl('lct', `releaseStage/tenants/${tenantId}`));

  const releaseStageByTenantItems = releaseStageByTenantData?.items ?? [];

  const releaseStageItems = releaseStageData?.items ?? [];
  const releaseStageConfig = React.useMemo<Item[]>(
    () => releaseStageItems ?? [],
    [releaseStageItems],
  );
  const releaseStageOptions = releaseStageConfig.map((item) => ({
    value: item.id,
    label: item.releaseStage,
  }));

  const strings = {
    announceOptionSelected: (option: any) => `${option.label}, selected.`,
    announceOptionDeselected: (option: any) => `${option.label}, deselected.`,
    announceValueCleared: 'All values deselected.',
    announceSearchResults: (count: number, searchString: string) => {
      switch (count) {
        case 0:
          return `No results found for "${searchString}"`;
        case 1:
          return `${count} result found for "${searchString}". Press down arrow to navigate through the results.`;
        default:
          return `${count} results found for "${searchString}". Press down arrow to navigate through the results.`;
      }
    },
    noResults: (searchString: string) => `No results found for "${searchString}"`,
    clearButtonLabel: 'Deselect all values.',
    searchLabel: 'Search',
  };

  let content = <LoadingIndicator data-testid="loading-release-stage-by-tenant" />;

  if (releaseStageBytenantError) {
    content = (
      <Typography data-testid="release-stage-by-tenant-error">
        {t('releaseStages.loadError')}
      </Typography>
    );
  } else if (!releaseStageByTenantLoading) {
    content = (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell role="columnheader">{t('releaseStages.pageTitle')}</TableCell>
            <TableCell role="columnheader" />
          </TableRow>
        </TableHead>
        <TableBody>
          {releaseStageByTenantItems.length === 0 ? (
            <TableRow>
              <TableCell colSpan={2}>
                <Typography color="error" data-testid="no-release-stage">
                  {t('releaseStages.noDataByTenant')}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            releaseStageByTenantItems.map((item: any) => (
              <TableRow key={item.ReleaseStage}>
                <TableCell
                  aria-colindex={1}
                  tabIndex={-1}
                  aria-describedby="config-list-table-release-stage"
                >
                  {item.ReleaseStage}
                </TableCell>
                <TableCell
                  aria-colindex={2}
                  tabIndex={-1}
                  aria-describedby="config-list-table-header-actions"
                  align="right"
                >
                  <UnbindReleaseStageConfigurationButton
                    onReload={() => {
                      releaseStageByTenantFetching();
                      releaseStageFetching();
                    }}
                    releaseStageName={item.ReleaseStage}
                    releaseStageId={item.ReleaseStageId}
                    tenantId={tenantId}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    );
  }
  return (
    <div data-testid="release-stage-configuration-page">
      <div data-testid="release-stage-combobox-configuration" className={classes.combobox}>
        <Combobox
          floatingLabel
          id="combobox-demo"
          label={t('releaseStages.selectReleaseStage')}
          strings={strings}
          options={releaseStageOptions}
          value={comboBoxValue}
          onChange={(value, data) => {
            const selectedOption = data.option!;
            setSelectedReleaseStageId(selectedOption.value ?? undefined);
            setSelectedReleaseStageName(String(selectedOption.label) ?? undefined);
            setReleaseStageConfigDialog(true);
            setComboBoxValue(selectedOption || emptyComboBox);
          }}
        />
      </div>
      <div className={classes.section}>{content}</div>
      <BindReleaseStageConfigurationDialog
        onClose={() => {
          setReleaseStageConfigDialog(false);
          setComboBoxValue(emptyComboBox);
        }}
        open={releaseStageConfigDialog}
        id={selectedReleaseStageId}
        data-testid="bind-release-stage-config-dialog"
        releaseStageId={selectedReleaseStageId}
        tenantId={tenantId}
        tenantName={tenant?.name}
        releaseStageName={selectedReleaseStageName}
        onSuccess={() => {
          releaseStageFetching();
          releaseStageByTenantFetching();
          setComboBoxValue(emptyComboBox);
        }}
      />
    </div>
  );
};
