import * as React from 'react';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import { ImageDialogData } from 'pages/Images/Images.types';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';

const styles = (theme: Theme) =>
  createStyles({
    addImageInput: {
      margin: theme.spacing(1, 0, 1),
    },
  });

export const useStyles = makeStyles(styles);

const emptyImageData: ImageDialogData = {
  amiId: '',
  version: '',
};

export interface CreateImageDialogProps extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateImageDialog: React.FunctionComponent<CreateImageDialogProps> = (props) => {
  const { id, open, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPost,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('lct', 'registry/'), { manual: true });

  const [showErrors, setShowErrors] = React.useState(false);
  const [formData, setFormData] = React.useState<ImageDialogData>(emptyImageData);
  const hasFormDataChanged = () => JSON.stringify(formData) !== JSON.stringify({ emptyImageData });
  const classes = useStyles(props);

  function create(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowErrors(true);
    if (hasFormDataChanged()) {
      doPost(formData);
    }
  }

  const close = React.useCallback(() => {
    setShowErrors(false);
    setFormData(emptyImageData);
    onClose();
  }, [onClose]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('images.addSuccess'), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
      onSuccess();
    }
  }, [
    clearSucceededRequests,
    close,
    enqueueSnackbar,
    formData.amiId,
    onSuccess,
    succeededRequests.length,
    t,
  ]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('images.addFailed', {
          message: failedRequests[0].error.message,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [
    clearFailedRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    formData.amiId,
    t,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={close} id={`${id}-title`}>
        {t('images.addImageTitle')}
      </DialogTitle>
      <form onSubmit={create} noValidate data-testid="create-image-form">
        <DialogContent>
          <TextField
            id={`${id}-image-id`}
            data-testid={`${id}-image-id`}
            label={'AMI ID'}
            onChange={(event) => setFormData({ ...formData, amiId: event.target.value.trim() })}
            fullWidth
            placeholder={'AMI ID'}
            required
          />
          <TextField
            id={`${id}-image-version`}
            data-testid={`${id}-image-verison`}
            label={'AMI Version'}
            onChange={(event) => setFormData({ ...formData, version: event.target.value.trim() })}
            fullWidth
            placeholder={'AMI Version'}
            required
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid="cancel-create-image-dialog" onClick={close}>
            {t('global.cancel')}
          </DefaultButton>
          <PrimaryButton
            data-testid="submit-create-image-dialog"
            disabled={loadingRequests}
            type="submit"
          >
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateImageDialog;
