import { useAppConfigContext } from 'contexts/AppConfigContext';
import { AppRouteContextProvider } from 'contexts/AppRouteContext';
import { Callback } from 'pages/Callback';
import { ErrorPage } from 'pages/Error';
import { ExternalPlaceholder } from 'pages/ExternalPlaceholder/ExternalPlaceholder';
import { FeatureFlagDefinition } from 'pages/FeatureFlag';
import { FeatureFlagDefinitionInformation } from 'pages/FeatureFlag/FeatureFlagDefinitionInformation';
import { FeatureFlagRegions } from 'pages/FeatureFlag/FeatureFlagRegions/FeatureFlagRegions';
import { FeatureFlagTenants } from 'pages/FeatureFlag/FeatureFlagTenants';
import { FeatureFlagList } from 'pages/FeatureFlagList';
import { GroupList } from 'pages/GroupList';
import { Home } from 'pages/Home';
import { LicenseList } from 'pages/LicenseList';
import { SignIn } from 'pages/SignIn';
import { SignOut } from 'pages/SignOut';
import { Tenant } from 'pages/Tenant';
import { TenantAuth } from 'pages/Tenant/TenantAuth';
import { TenantDisplay } from 'pages/Tenant/TenantDisplay';
import { TenantFeatureFlags } from 'pages/Tenant/TenantFeatureFlags';
import { TenantGroups } from 'pages/Tenant/TenantGroups';
import { TenantInformation } from 'pages/Tenant/TenantInformation';
import { TenantLicenses } from 'pages/Tenant/TenantLicenses';
import { TenantIdp } from 'pages/TenantIdp';
import { TenantList } from 'pages/TenantList';
import { LearnSites } from 'pages/LearnSites';
import { SiteConfiguration } from 'components/SiteConfiguration/SiteConfiguration';
import * as React from 'react';
import { Switch } from 'react-router-dom';
import { MobileConfiguration } from 'pages/Tenant/TenantMobile';
import {
  JobExecutionRefreshButton,
  TenantExecutionDetail,
  TenantJobExecutions,
} from 'pages/Tenant/TenantJobExecutions';
import { ConfigurationRefreshButton } from 'components/SiteConfiguration/ConfigurationRefreshButton';
import { TenantLogs } from 'pages/Tenant/TenantLogs';
import { Images } from 'pages/Images/Images';
import { ReleaseStages } from 'pages/ReleaseStages';
import { ReleaseStageConfiguration } from 'components/ReleaseStageConfiguration';
import { DdaDefinitionsInformation } from 'pages/Dda';
import { FeatureFlagFleets } from '../pages/FeatureFlag/FeatureFlagFleets';
import { AppRoute, AppRouteProps } from './AppRoute';
import { LearnClients } from '../pages/LearnClients/LearnClients';
import { JobList } from '../components/JobList';
import { TenantRouteProps } from '../pages/Tenant/Tabs/TenantRoutes';
import { SiteInformation } from '../components/SiteInformation/SiteInformation';
import { TenantSupportLogin } from '../components/TenantSupportLogin';
import { TenantChildren } from '../pages/Tenant/TenantChildren';

// See https://reactrouter.com/web/example/route-config
// The order of items corresponds to order of tabs in the UI.

export const externalRoutes: AppRouteProps[] = [
  {
    component: Callback,
    path: '/callback',
    public: true,
  },
  {
    component: ExternalPlaceholder,
    path: '/:tenantId',
  },

  // There are no /sign-in-callback or / (top-level) routes defined here.
  // Instead, <AuthProvider> handles those. See that component for more
  // explanation.
];

function generateRoutes(prefix: string): TenantRouteProps[] {
  return [
    {
      component: TenantInformation,
      exact: true,
      name: 'tenantProfile.pageTitle',
      path: `/${prefix}/:tenantId`,
      headerActionComponent: TenantSupportLogin,
    },
    {
      component: TenantInformation,
      exact: true,
      hasTab: true,
      name: 'tenantInformation.pageTitle',
      path: `/${prefix}/:tenantId/information`,
      headerActionComponent: TenantSupportLogin,
    },
    {
      component: TenantChildren,
      exact: true,
      hasTab: true,
      name: 'tenantAssets.pageTitle',
      path: `/${prefix}/:tenantId/assets`,
    },
    {
      component: SiteInformation,
      exact: true,
      hasTab: true,
      name: 'learnSiteInformation.pageTitle',
      path: `/${prefix}/:tenantId/site`,
      tenantTypes: ['LearnInstance'],
      authorization: [
        {
          service: 'il4',
          permission: 'read',
        },
      ],
    },
    {
      component: TenantDisplay,
      exact: true,
      hasTab: true,
      name: 'tenantDisplay.pageTitle',
      path: `/${prefix}/:tenantId/display`,
    },
    {
      component: TenantGroups,
      hasTab: true,
      name: 'tenantGroups.pageTitle',
      path: `/${prefix}/:tenantId/groups`,
    },
    {
      component: TenantAuth,
      exact: true,
      hasTab: true,
      name: 'tenantAuth.pageTitle',
      path: `/tenants/:tenantId/auth`,
    },
    {
      component: TenantLicenses,
      hasTab: true,
      name: 'tenantLicenses.pageTitle',
      path: `/${prefix}/:tenantId/licenses`,
    },
    {
      component: TenantFeatureFlags,
      hasTab: true,
      name: 'tenantFeatureFlags.pageTitle',
      path: `/${prefix}/:tenantId/flags`,
      authorization: [
        {
          service: 'feature-flags',
          permission: 'access',
        },
      ],
    },
    {
      component: ReleaseStageConfiguration,
      hasTab: true,
      name: 'releaseStages.pageTitle',
      path: `/${prefix}/:tenantId/release-stage`,
      authorization: [
        {
          service: 'il4',
          permission: 'read',
        },
      ],
    },
    {
      component: SiteConfiguration,
      hasTab: true,
      name: 'siteConfiguration.pageTitle',
      path: `/${prefix}/:tenantId/configuration`,
      headerActionComponent: ConfigurationRefreshButton,
      authorization: [
        {
          service: 'il4',
          permission: 'read',
        },
      ],
    },
    {
      component: JobList,
      hasTab: true,
      name: 'jobService.pageTitle',
      path: `/${prefix}/:tenantId/jobs`,
      authorization: [
        {
          service: 'il4',
          permission: 'read',
        },
      ],
    },
    {
      component: TenantJobExecutions,
      hasTab: true,
      exact: true,
      name: 'jobService.executionPageTitle',
      path: `/${prefix}/:tenantId/executions`,
      headerActionComponent: JobExecutionRefreshButton,
      authorization: [
        {
          service: 'il4',
          permission: 'read',
        },
      ],
    },
    {
      component: TenantExecutionDetail,
      hasTab: false,
      name: 'jobService.executionDetail',
      path: `/${prefix}/:tenantId/executions/:executionId`,
      headerActionComponent: JobExecutionRefreshButton,
      authorization: [
        {
          service: 'il4',
          permission: 'read',
        },
      ],
    },
    {
      component: MobileConfiguration,
      hasTab: true,
      name: 'Mobile',
      path: `/${prefix}/:tenantId/mobileconfiguration`,
      authorization: [
        {
          service: 'mobile',
          permission: 'access',
        },
      ],
    },
    {
      component: TenantLogs,
      hasTab: true,
      name: 'tenantAuditLogs.title',
      path: `/${prefix}/:tenantId/logs`,
      authorization: [
        {
          service: 'il4',
          permission: 'read',
        },
      ],
    },
    {
      component: DdaDefinitionsInformation,
      exact: true,
      hasTab: true,
      name: 'ddaInformation.pageTitle',
      path: `/${prefix}/:tenantId/dda`,
    },
  ];
}

const tenantChildRoutes = generateRoutes('tenants');
const clientChildRoutes = generateRoutes('client');
const siteChildRoutes = generateRoutes('learn-site');

export const internalRoutes: AppRouteProps[] = [
  {
    component: Home,
    exact: true,
    path: '/',
  },
  {
    component: Callback,
    path: '/callback',
    public: true,
  },
  {
    component: SignIn,
    path: '/sign-in',
    public: true,
  },
  {
    component: SignOut,
    path: '/sign-out',
    public: true,
  },
  {
    component: LearnSites,
    exact: true,
    hasTab: true,
    name: 'learnSites.pageTitle',
    path: '/learn-site',
    authorization: [
      {
        service: 'il4',
        permission: 'read',
      },
    ],
  },
  {
    component: TenantList,
    exact: true,
    hasTab: true,
    name: 'tenantList.pageTitle',
    path: '/tenants',
    authorization: [
      {
        service: 'tenant',
        permission: 'read',
      },
    ],
  },
  {
    component: LicenseList,
    hasTab: true,
    name: 'licenseList.pageTitle',
    path: '/licenses',
  },
  {
    component: GroupList,
    hasTab: true,
    name: 'groupList.pageTitle',
    path: '/groups',
    authorization: [
      {
        service: 'fnds-group',
        permission: 'read',
      },
    ],
  },
  {
    component: FeatureFlagList,
    exact: true,
    hasTab: true,
    name: 'featureFlagList.pageTitle',
    path: '/feature-flags',
    authorization: [
      {
        service: 'feature-flags',
        permission: 'access',
      },
    ],
  },
  {
    component: ReleaseStages,
    hasTab: true,
    name: 'releaseStages.pageTitle',
    path: '/release-stage',
    exact: true,
    authorization: [
      {
        service: 'il4',
        permission: 'read',
      },
    ],
  },
  {
    component: Images,
    hasTab: true,
    name: 'images.pageTitle',
    path: '/registry',
    exact: true,
    authorization: [
      {
        service: 'il4',
        permission: 'read',
      },
    ],
  },
  {
    component: LearnClients,
    exact: true,
    hasTab: true,
    name: 'learnClients.pageTitle',
    path: '/client',
    authorization: [
      {
        service: 'il4',
        permission: 'read',
      },
    ],
  },
  {
    component: Tenant,
    path: '/client/:tenantId',
    name: 'learnClient.pageTitle',
    authorization: [
      {
        service: 'tenant',
        permission: 'read',
      },
    ],
    routes: clientChildRoutes,
  },
  {
    component: Tenant,
    path: '/learn-site/:tenantId',
    name: 'learnClient.pageTitle',
    authorization: [
      {
        service: 'tenant',
        permission: 'read',
      },
    ],
    routes: siteChildRoutes,
  },
  {
    component: TenantIdp,
    exact: true,
    name: 'tenantIdp.pageTitle',
    path: '/tenants/:tenantId/auth/:idpId',
    authorization: [
      {
        service: 'identity-provider',
        permission: 'read',
      },
      {
        service: 'tenant',
        permission: 'read',
      },
    ],
  },
  {
    component: Tenant,
    path: '/tenants/:tenantId',
    authorization: [
      {
        service: 'tenant',
        permission: 'read',
      },
    ],
    routes: tenantChildRoutes,
  },
  {
    component: FeatureFlagDefinition,
    path: '/feature-flags/:flagKey',
    authorization: [
      {
        service: 'feature-flags',
        permission: 'access',
      },
    ],
    routes: [
      {
        component: FeatureFlagDefinitionInformation,
        exact: true,
        name: 'featureFlagDefinitionInfo.globalSettingsTitle',
        path: '/feature-flags/:flagKey',
      },
      {
        component: FeatureFlagDefinitionInformation,
        exact: true,
        hasTab: true,
        name: 'featureFlagDefinitionInfo.globalSettingsTitle',
        path: '/feature-flags/:flagKey/information',
      },
      {
        component: FeatureFlagTenants,
        exact: true,
        hasTab: true,
        name: 'featureFlagDefinitionTenants.pageTitle',
        path: '/feature-flags/:flagKey/tenants',
      },
      {
        component: FeatureFlagRegions,
        exact: true,
        hasTab: true,
        name: 'featureFlagDefinitionRegions.regionsLabel',
        path: '/feature-flags/:flagKey/regions',
      },
      {
        component: FeatureFlagFleets,
        exact: true,
        hasTab: true,
        name: 'featureFlagDefinitionFleets.fleetsLabel',
        path: '/feature-flags/:flagKey/fleets',
      },
    ],
  },
  {
    component: ErrorPage,
    path: '*',
  },
];

export const Routes: React.FC = () => {
  const { loading: configLoading, deployment } = useAppConfigContext();

  if (configLoading || !deployment) {
    return null;
  }

  const routes = deployment.internal ? internalRoutes : externalRoutes;

  return (
    <AppRouteContextProvider routes={routes}>
      <Switch>
        {routes.map((route, i) => (
          <AppRoute key={i} {...route} />
        ))}
      </Switch>
    </AppRouteContextProvider>
  );
};
