import { Unlock } from '@bb-ui/icons/dist/small';
import { OutlineButton } from '@bb-ui/react-library';
import BbTooltip from '@bb-ui/react-library/dist/components/Tooltip/Tooltip';
import UnbindReleaseStageConfigurationDialog from 'dialogs/UnbindReleaseStageConfigurationDialog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface UnbindReleaseStageConfigurationButtonProps {
  onReload: () => void;
  releaseStageName: string;
  releaseStageId: string;
  tenantId: string;
}

export const UnbindReleaseStageConfigurationButton: React.FunctionComponent<
  UnbindReleaseStageConfigurationButtonProps
> = ({ onReload, releaseStageName, releaseStageId, tenantId }) => {
  const { t } = useTranslation();
  const [unbindReleaseStageConfigurationDialogOpen, setUnbindReleaseStageConfigurationDialogOpen] =
    React.useState(false);

  return (
    <>
      <BbTooltip
        title={
          t('releaseStages.unbindReleaseStageButtonToolTip', {
            ReleaseStageName: releaseStageName,
          }) || ''
        }
        arrow
        placement="top"
      >
        <OutlineButton
          startIcon={<Unlock />}
          data-testid="delete-release-stage-configuration-button"
          onClick={() => setUnbindReleaseStageConfigurationDialogOpen(true)}
        >
          {t('releaseStages.unbindReleaseStageButton')}
        </OutlineButton>
      </BbTooltip>
      <UnbindReleaseStageConfigurationDialog
        id="unbind-release-stage-dialog"
        onClose={() => setUnbindReleaseStageConfigurationDialogOpen(false)}
        onSuccess={onReload}
        open={unbindReleaseStageConfigurationDialogOpen}
        releaseStageName={releaseStageName}
        releaseStageId={releaseStageId}
        tenantId={tenantId}
      />
    </>
  );
};
