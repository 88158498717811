import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, OutlineButton } from '@bb-ui/react-library';
import { ContentView } from '@bb-ui/icons/dist/small';
import { ReleaseStageDrawer } from './ReleaseStageDrawer';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
}));

export interface ReleaseStageDrawerButtonProps {
  releaseName: string;
  releaseStageId: string;
  releaseVersion: string;
  releaseNumberOfMembers: number;
}
export const ReleaseStageDrawerButton: React.FunctionComponent<ReleaseStageDrawerButtonProps> = ({
  releaseName,
  releaseStageId,
  releaseNumberOfMembers,
  releaseVersion,
}) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const classes = useStyles();
  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  return (
    <>
      <OutlineButton
        startIcon={<ContentView />}
        data-testid="instances-button-release-stage"
        onClick={() => setDrawerOpen(true)}
        className={classes.button}
      >
        {t('releaseStages.releaseStageViewLearnInstancesButton')}
      </OutlineButton>
      <ReleaseStageDrawer
        isOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        releaseName={releaseName}
        releaseStageId={releaseStageId}
        releaseVersion={releaseVersion}
        releaseNumberOfMembers={releaseNumberOfMembers}
      />
    </>
  );
};
