import { Settings } from '@bb-ui/icons/dist/small';
import { makeStyles, PrimaryButton } from '@bb-ui/react-library';
import { ReleaseStageUpgradeButtonDialog } from 'dialogs/ReleaseStageUpgradeButtonDialog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ReleaseStageUpgradeButtonProps {
  releaseStageId: string;
  releaseStageName: string;
  onSuccess: () => void;
}

const useStyles = makeStyles((theme) => ({
  upgradeButton: {
    marginRight: theme.spacing(1),
  },
}));

export const ReleaseStageUpgradeButton: React.FunctionComponent<ReleaseStageUpgradeButtonProps> = (
  props,
) => {
  const { releaseStageId, releaseStageName, onSuccess } = props;
  const { t } = useTranslation();
  const [releaseStageUpgradeButtonDialogOpen, setReleaseStageUpgradeButtonDialogOpen] =
    React.useState(false);
  const classes = useStyles();
  return (
    <>
      <PrimaryButton
        className={classes.upgradeButton}
        startIcon={<Settings />}
        data-testid="upgrade-all-release-stage-button"
        onClick={() => setReleaseStageUpgradeButtonDialogOpen(true)}
      >
        {t('releaseStages.releaseStageDrawer.upgradeButtonTitle')}
      </PrimaryButton>
      <ReleaseStageUpgradeButtonDialog
        id="upgrade-release-stage-dialog"
        onClose={() => setReleaseStageUpgradeButtonDialogOpen(false)}
        releaseStageId={releaseStageId}
        releaseStageName={releaseStageName}
        open={releaseStageUpgradeButtonDialogOpen}
        onSuccess={() => {
          setReleaseStageUpgradeButtonDialogOpen(false);
          onSuccess();
        }}
      />
    </>
  );
};
