import * as React from 'react';
import { Edit } from '@bb-ui/icons/dist/small';
import { useTranslation } from 'react-i18next';
import { makeStyles, OutlineButton } from '@bb-ui/react-library';
import { EditImagesDialog } from '../../dialogs/EditImagesDialog';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
}));

export interface EditImagesButtonProps {
  onReload: () => void;
  amiId: string;
  version: string;
  approved: boolean;
  createdAt: number;
}

export const EditImagesButton: React.FunctionComponent<EditImagesButtonProps> = ({
  onReload,
  amiId,
  version,
  approved,
  createdAt,
}) => {
  const { t } = useTranslation();
  const [editButtonDialogOpen, setEditButtonDialogOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <OutlineButton
        startIcon={<Edit />}
        data-testid="edit-button-images"
        className={classes.button}
        onClick={() => setEditButtonDialogOpen(true)}
      >
        {t('images.imageRegistryEditButton')}
      </OutlineButton>
      <EditImagesDialog
        id="edit-images-dialog"
        onClose={() => setEditButtonDialogOpen(false)}
        onSuccess={onReload}
        open={editButtonDialogOpen}
        amiId={amiId}
        version={version}
        approved={approved}
        createdAt={createdAt}
      />
    </>
  );
};
