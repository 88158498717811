import * as React from 'react';
import { DefaultButton, DialogActions, DialogContent, PrimaryButton } from '@bb-ui/react-library';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import useRestApi from 'hooks/useRestApi';
import { useSnackbar } from 'hooks/useSnackbar';
import { ReleaseStageDialogData } from 'pages/ReleaseStages/ReleaseStages.types';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';
import { Banner } from '@bb-ui/react-library/dist/components/Banner';

export interface EditReleaseStageDialogProps extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  initialData: ReleaseStageDialogData;
  releaseStageID: string;
}

export const EditReleaseStageDialog: React.FunctionComponent<EditReleaseStageDialogProps> = (
  props,
) => {
  const { id, open, onClose, onSuccess, initialData, releaseStageID } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPatch,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('lct', 'releaseStage'), { manual: true });
  const [showErrors, setShowErrors] = React.useState(false);
  const [formData, setFormData] = React.useState<ReleaseStageDialogData>(initialData);

  const hasFormDataChanged = () => JSON.stringify(formData) !== JSON.stringify(initialData);
  const validateField = (fieldValue: string, fieldName: string): string => {
    if (!fieldValue.trim()) {
      return t(`releaseStages.editReleaseStageDialog.${fieldName}ErrorMessage`);
    }
    return '';
  };

  const getFieldError = (fieldKey: keyof ReleaseStageDialogData): string => {
    return showErrors ? validateField(formData[fieldKey], fieldKey) : '';
  };

  function edit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowErrors(true);
    const errors = {
      releaseStage: validateField(formData.releaseStage, 'releaseStage'),
      learnVersion: validateField(formData.learnVersion, 'learnVersion'),
    };
    const hasErrors = Object.values(errors).some((error) => error !== '');
    if (hasErrors) {
      return;
    }
    if (hasFormDataChanged()) {
      doPatch(releaseStageID, formData);
    }
  }

  const close = React.useCallback(() => {
    setShowErrors(false);
    setFormData(initialData);
    onClose();
  }, [initialData, onClose]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('releaseStages.editReleaseStageDialog.succededMessage'), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
      onSuccess();
    }
  }, [
    clearSucceededRequests,
    close,
    enqueueSnackbar,
    formData.releaseStage,
    formData.learnVersion,
    onSuccess,
    succeededRequests.length,
    t,
  ]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('releaseStages.editReleaseStageDialog.editFailed', {
          message: failedRequests[0].error.message,
          ReleaseStage: initialData.releaseStage,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [
    clearFailedRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    formData.releaseStage,
    formData.learnVersion,
    t,
    initialData.releaseStage,
  ]);
  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={close} id={`${id}-title`}>
        {t('releaseStages.editReleaseStageDialog.title')}
      </DialogTitle>
      <form onSubmit={edit} noValidate data-testid="edit-release-stage-form">
        <DialogContent>
          {!hasFormDataChanged() ? (
            <Banner severity="info">
              {t('releaseStages.editReleaseStageDialog.banner-message')}
            </Banner>
          ) : null}
          <TextField
            id={`${id}-release-stage-name`}
            data-testid={`${id}-release-stage-name`}
            label={t('releaseStages.editReleaseStageDialog.name')}
            value={formData.releaseStage}
            onChange={(event) =>
              setFormData({ ...formData, releaseStage: event.target.value.trim() })
            }
            fullWidth
            error={!!getFieldError('releaseStage')}
            helperText={getFieldError('releaseStage')}
          />
          <TextField
            id={`${id}-release-stage-version`}
            data-testid={`${id}-release-stage-version`}
            label={t('releaseStages.editReleaseStageDialog.version')}
            value={formData.learnVersion}
            onChange={(event) =>
              setFormData({ ...formData, learnVersion: event.target.value.trim() })
            }
            fullWidth
            error={!!getFieldError('learnVersion')}
            helperText={getFieldError('learnVersion')}
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid="cancel-edit-release-stage-dialog" onClick={close}>
            {t('global.cancel')}
          </DefaultButton>
          <PrimaryButton
            data-testid="submit-edit-release-stage-dialog"
            disabled={loadingRequests || !hasFormDataChanged()}
            type="submit"
          >
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditReleaseStageDialog;
