import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { TextField, Typography, createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useSnackbar } from 'hooks/useSnackbar';
import { LearnConfigDetails } from 'App.types';
import Form from '@rjsf/material-ui';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useTenantContext } from 'contexts/TenantContext';
import { Banner } from '@bb-ui/react-library/dist/components/Banner';

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      backgroundColor: theme.palette.background.b2,
    },
    dialogDescription: {
      borderBottom: `1px solid ${theme.palette.border.main}`,
      paddingLeft: '1rem',
      paddingBottom: '5px',
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.b2,
    },
    updateConfigInput: {
      margin: theme.spacing(1, 0, 1),
    },
  });

export const useStyles = makeStyles(styles);

export interface UpdateConfigurationDialogProps extends DialogProps {
  selectedDomain: string;
  loading?: boolean;
  error?: boolean;
  uiSchema?: Record<string, any>;
  schema: Record<string, any>;
  newDomain: boolean;
  formData?: Record<string, any>;
  onClose: () => void;
}

export const ActionsUpdateConfigurationDialog: React.FunctionComponent<
  UpdateConfigurationDialogProps
> = (props) => {
  const {
    id,
    open,
    onClose,
    selectedDomain,
    schema,
    uiSchema,
    error,
    loading,
    newDomain,
    formData,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { tenantId } = useTenantContext();

  interface TenantStateData {
    updated: number;
    LockId: string;
    created: number;
    TenantId: string;
    State: string;
  }

  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPost,
    doPut,
    loadingRequests,
    failedRequests,
    succeededRequests,
  } = useRestApi(apiUrl('config', `tenants/${tenantId}/capabilityConfigs`), { manual: true });

  // Fetches the tenant state data by tenant ID using the useRestApi hook.
  const { data: tenantStateDataById } = useRestApi<TenantStateData>(
    apiUrl('tenancy', `tenants/${tenantId}/state`),
  );

  const [showErrors, setShowErrors] = React.useState(false);

  // Called if we tried to create or the user cancelled out of the dialog.
  const close = React.useCallback(() => {
    setShowErrors(false);
    onClose();
  }, [onClose]);

  function update(s: any, event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (newDomain) {
      const body = {
        configuration: s.formData,
        tenantId: tenantId,
        domain: selectedDomain,
      };

      doPost(body);
    } else {
      const body = {
        configuration: s.formData,
      };
      doPut(selectedDomain, body);
    }
  }

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('siteConfiguration.updateSucceeded'), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
    }
  }, [clearSucceededRequests, close, enqueueSnackbar, succeededRequests.length, t]);

  // If there was an error creating, show a snackbar notification. We close
  // because otherwise the snackbar will appear under the modal overlay, which
  // is difficult to see. We should revisit this.

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('siteConfiguration.updateFailed', {
          message: failedRequests[0].error.message,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [clearFailedRequests, close, enqueueSnackbar, failedRequests, failedRequests.length, t]);

  let content: React.ReactElement;
  if (error) {
    content = (
      <ErrorMessage
        data-testid="config-schema-error"
        title={t('global.fetchError')}
        variant="block"
      />
    );
  } else if (loading || loadingRequests || !tenantStateDataById) {
    content = <LoadingIndicator data-testid="config-schema-loading" />;
  } else {
    const isAvailable: boolean = tenantStateDataById?.State === 'AVAILABLE';

    const updatedUiSchema = {
      ...uiSchema,
      'ui:submitButtonOptions': {
        norender: !isAvailable,
      },
    };

    content = (
      <>
        {!isAvailable && (
          <Banner severity="info">
            {t('learnSiteInformation.notAvailableStateModalInformation')}
          </Banner>
        )}
        <Form
          schema={schema}
          formData={formData}
          onSubmit={update}
          uiSchema={updatedUiSchema}
          disabled={!isAvailable}
        />
      </>
    );
  }

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={close} id={`${id}-title`} className={classes.dialogTitle}>
        {t('siteConfiguration.title')}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default ActionsUpdateConfigurationDialog;
