import {
  DefaultButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  PrimaryButton,
} from '@bb-ui/react-library';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { useEnqueueBbSnackbar } from '@bb-ui/react-library/dist/components/Snackbar/SnackbarContent';
import useRestApi from 'hooks/useRestApi';
import { SnackbarKey, useSnackbar } from 'notistack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { apiUrl } from 'utils/apiUrl';
import { JobIds, JobRequest } from 'pages/ReleaseStages/ReleaseStages.types';

export interface ReleaseStageUpgradeButtonDialogProps extends DialogProps {
  onClose: () => void;
  releaseStageId: string;
  releaseStageName: string;
  onSuccess: () => void;
}

export const ReleaseStageUpgradeButtonDialog: React.FunctionComponent<
  ReleaseStageUpgradeButtonDialogProps
> = (props) => {
  const { id, open, onClose, releaseStageId, releaseStageName, onSuccess } = props;
  const { t } = useTranslation();
  const [snackbarKey, setSnackbarKey] = React.useState<SnackbarKey | null>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    failedRequests,
    succeededRequests,
    clearFailedRequests,
    clearSucceededRequests,
    doPost,
    loadingRequests,
  } = useRestApi(apiUrl('lct', `releaseStageJob/${releaseStageId}/triggerCustomBatch`), {
    manual: true,
  });
  const dismissSnackbar = React.useCallback(() => {
    if (snackbarKey) {
      closeSnackbar(snackbarKey);
      setSnackbarKey(null);
    }
  }, [closeSnackbar, snackbarKey]);

  const submitPost = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSnackbarKey(enqueueSnackbar('Submitting Execution...', { variant: 'info' }));
    const body: JobRequest = {
      jobId: JobIds.UPGRADE,
    };
    doPost(body);
  };

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      onSuccess();
      clearFailedRequests();
      dismissSnackbar();
      setSnackbarKey(enqueueSnackbar('Error submitting execution', { variant: 'error' }));
    }
  }, [
    clearFailedRequests,
    closeSnackbar,
    dismissSnackbar,
    enqueueSnackbar,
    failedRequests,
    onSuccess,
  ]);

  const showSuccessSnackbar = useEnqueueBbSnackbar(
    { snackbarMessage: 'Execution Submitted' },
    {
      actionMessage: 'View Details',
      onActionClick: () => {
        const result = succeededRequests.at(0);
        const tenantId: string | undefined = result?.response?.data?.tenantId;
        const executionId: string | undefined = result?.response?.data?.executionId;
        if (tenantId && executionId) {
          history.push(`/tenants/${tenantId}/executions/${executionId}`);
          closeSnackbar();
        }
      },
      closeButtonAriaLabel: 'close',
    },
  );
  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      onSuccess();
      dismissSnackbar();
      showSuccessSnackbar();
      clearSucceededRequests();
    }
  }, [
    clearSucceededRequests,
    dismissSnackbar,
    onSuccess,
    showSuccessSnackbar,
    succeededRequests.length,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={() => onClose()}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={() => onClose()} id={`${id}-title`}>
        {t('releaseStages.releaseStageUpgradeDialog.title')}
      </DialogTitle>
      <form onSubmit={submitPost} noValidate data-testid="release-stage-upgrade-button-form">
        <DialogContent>
          <DialogContentText
            id={`${id}-release-stage-upgrade-button`}
            data-testid={`${id}-release-stage-upgrade-content-text`}
          >
            {t('releaseStages.releaseStageUpgradeDialog.content', {
              ReleaseStageName: releaseStageName,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton type="submit" disabled={loadingRequests}>
            {t('releaseStages.releaseStageUpgradeDialog.confirmUpgradeTenantsFromReleaseStage')}
          </PrimaryButton>
          <DefaultButton onClick={() => onClose()}>
            {t('releaseStages.releaseStageUpgradeDialog.cancelUpgradeTenantsFromReleaseStage')}
          </DefaultButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
