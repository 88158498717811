import * as React from 'react';
import { Trash } from '@bb-ui/icons/dist/small';
import { useTranslation } from 'react-i18next';
import { makeStyles, OutlineButton } from '@bb-ui/react-library';
import { DeleteReleaseStageDialog } from 'dialogs/DeleteReleaseStageDialog';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
}));

export interface DeleteReleaseStageButtonProps {
  onReload: () => void;
  releaseStageID: string;
  releaseStageName: string;
}

export const DeleteReleaseStageButton: React.FunctionComponent<DeleteReleaseStageButtonProps> = ({
  onReload,
  releaseStageID,
  releaseStageName,
}) => {
  const { t } = useTranslation();
  const [deleteButtonDialogOpen, setDeleteButtonDialogOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <OutlineButton
        startIcon={<Trash />}
        data-testid="delete-button-release-stage"
        className={classes.button}
        onClick={() => setDeleteButtonDialogOpen(true)}
      >
        {t('releaseStages.releaseStageRemoveButton')}
      </OutlineButton>
      <DeleteReleaseStageDialog
        id="delete-release-stage-dialog"
        onClose={() => setDeleteButtonDialogOpen(false)}
        onSuccess={onReload}
        open={deleteButtonDialogOpen}
        releaseStageID={releaseStageID}
        releaseStageName={releaseStageName}
      />
    </>
  );
};
