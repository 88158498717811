import { DefaultButton, Dialog, DialogContent, PrimaryButton } from '@bb-ui/react-library';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import useRestApi from 'hooks/useRestApi';
import { useSnackbar } from 'hooks/useSnackbar';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';

export interface BindReleaseStageConfigurationDialogProps extends DialogProps {
  onClose(): void;
  releaseStageName: string;
  tenantId: string;
  tenantName: string | undefined;
  releaseStageId: string;
  onSuccess(): void;
}

export const BindReleaseStageConfigurationDialog: React.FunctionComponent<
  BindReleaseStageConfigurationDialogProps
> = (props) => {
  const { id, open, onClose, releaseStageId, releaseStageName, tenantId, tenantName, onSuccess } =
    props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { clearFailedRequests, clearSucceededRequests, doPost, succeededRequests, failedRequests } =
    useRestApi(apiUrl('lct', `releaseStage/tenants/${tenantId}`));

  function bindReleaseStage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (releaseStageId) {
      const body = {
        releaseStageId,
        tenantName,
      };
      doPost(body);
    }
  }

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('releaseStages.bindReleaseStageDialog.succededMessage'), {
        variant: 'success',
      });
      clearSucceededRequests();
      onClose();
      onSuccess();
    }
  }, [clearSucceededRequests, onClose, enqueueSnackbar, onSuccess, succeededRequests.length, t]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(t('releaseStages.bindReleaseStageDialog.errorMessage'), { variant: 'error' });
      clearFailedRequests();
      onClose();
    }
  }, [
    clearFailedRequests,
    onClose,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    t,
    releaseStageName,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={onClose}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <form onSubmit={bindReleaseStage} noValidate data-testid="bind-release-stage-form">
        <DialogTitle onClose={onClose} id={`${id}-title`}>
          {t('releaseStages.bindReleaseStageDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id={`${id}-release-stage-bind-content-text`}
            data-testid={`${id}-release-stage-bind-content-text`}
          >
            {t('releaseStages.bindReleaseStageDialog.content', {
              ReleaseStageName: releaseStageName,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton type="submit">
            {t('releaseStages.bindReleaseStageDialog.confirmDeleteReleaseStage')}
          </PrimaryButton>
          <DefaultButton onClick={() => onClose()}>
            {t('releaseStages.bindReleaseStageDialog.cancelDeleteReleaseStage')}
          </DefaultButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default BindReleaseStageConfigurationDialog;
