import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, makeStyles, createStyles } from '@bb-ui/react-library';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { useSnackbar } from 'hooks/useSnackbar';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { TenantTypes } from 'App.types';

const EXCLUDED_TENANT_TYPES = ['ReleaseStage', 'LearnDms', 'Illuminate'];

const styles = (theme: Theme) =>
  createStyles({
    addTenantInput: {
      margin: theme.spacing(2, 0, 1),
    },
  });

export const useStyles = makeStyles(styles);

export interface CreateTenantDialogProps extends DialogProps {
  onClose: () => void;
}

export const CreateTenantDialog: React.FunctionComponent<CreateTenantDialogProps> = (props) => {
  const { id, open, onClose } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPost,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('tenancy', 'tenants'), { manual: true });
  const [showErrors, setShowErrors] = React.useState(false);
  const [tenantName, setTenantName] = React.useState('');
  const [tenantDescription, setTenantDescription] = React.useState('');
  const [uriComponent, setUriComponent] = React.useState('');
  const [hostname, setHostName] = React.useState('');
  const [region, setRegion] = React.useState('');
  const [tenantType, setTenantType] = React.useState<string>('');
  const classes = useStyles(props);

  // Form validation errors. They're only visible if the user has tried to
  // submit the form at least once. One day we may want to limit the types of
  // characters used, etc.

  const tenantNameError =
    tenantName === '' ? t('createTenantDialog.errors.tenantNameRequired') : '';

  const tenantRegionError =
    region === '' ? t('createTenantDialog.errors.tenantRegionRequired') : '';

  // Called if we tried to create or the user cancelled out of the dialog.

  const close = React.useCallback(() => {
    setShowErrors(false);
    onClose();
  }, [onClose]);

  // If we successfully created, then we're done. We assume one request at a time.

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('createTenantDialog.createSucceeded', { name: tenantName }), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
    }
  }, [clearSucceededRequests, close, enqueueSnackbar, tenantName, succeededRequests.length, t]);

  // If there was an error creating, show a snackbar notification. We close
  // because otherwise the snackbar will appear under the modal overlay, which
  // is difficult to see. We should revisit this.

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('createTenantDialog.createFailed', {
          message: failedRequests[0].error.message,
          name: tenantName,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [
    clearFailedRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    tenantName,
    t,
  ]);

  // Starts a creation via API attempt.

  function create(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowErrors(true);

    if (tenantNameError || tenantRegionError) {
      return;
    }

    doPost({
      name: tenantName,
      description: tenantDescription,
      uriComponent,
      hostname,
      region,
      tenantType,
    });
  }

  function handleTenantType(event: any) {
    setTenantType(event.target.value);
  }

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={close} id={`${id}-title`}>
        {t('createTenantDialog.title')}
      </DialogTitle>
      <form onSubmit={create} noValidate data-testid="create-tenant-form">
        <DialogContent>
          <TextField
            className={classes.addTenantInput}
            error={showErrors && tenantNameError !== ''}
            helperText={showErrors && tenantNameError}
            id="create-tenant-name-input"
            label={t('createTenantDialog.tenantName')}
            onChange={(event) => setTenantName(event.target.value.trim())}
            placeholder={t('createTenantDialog.tenantName')}
            fullWidth
            autoFocus
            required
          />
          <TextField
            className={classes.addTenantInput}
            id="create-tenant-description-input"
            label={t('createTenantDialog.tenantDescription')}
            onChange={(event) => setTenantDescription(event.target.value)}
            placeholder={t('createTenantDialog.tenantDescription')}
            fullWidth
          />
          <TextField
            className={classes.addTenantInput}
            id="create-tenant-uricomponent-input"
            label={t('createTenantDialog.uriComponent')}
            onChange={(event) => setUriComponent(event.target.value)}
            placeholder={t('createTenantDialog.uriComponent')}
            fullWidth
          />
          <TextField
            className={classes.addTenantInput}
            id="create-tenant-hostname-input"
            label={t('createTenantDialog.hostname')}
            onChange={(event) => setHostName(event.target.value)}
            placeholder={t('createTenantDialog.hostname')}
            fullWidth
          />
          <TextField
            className={classes.addTenantInput}
            error={showErrors && tenantRegionError !== ''}
            helperText={showErrors && tenantRegionError}
            id="create-tenant-region-input"
            label={t('createTenantDialog.region')}
            onChange={(event) => setRegion(event.target.value.trim())}
            placeholder={t('createTenantDialog.region')}
            fullWidth
            required
          />
          <TextField
            id="create-tenant-tenant-type-input"
            select
            label={t('createTenantDialog.tenantType')}
            onChange={handleTenantType}
            placeholder={t('createTenantDialog.tenantType')}
            value={tenantType}
            fullWidth
          >
            {TenantTypes.filter((option) => !EXCLUDED_TENANT_TYPES.includes(option)).map(
              (option) => (
                <MenuItem button onClick={handleTenantType} key={option} value={option}>
                  {t(`tenantTypes.${option.toLowerCase()}`)}
                </MenuItem>
              ),
            )}
          </TextField>
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid={`cancel-${id}`} onClick={close}>
            {t('global.cancel')}
          </DefaultButton>
          <PrimaryButton data-testid={`submit-${id}`} disabled={loadingRequests} type="submit">
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateTenantDialog;
