import React from 'react';
import { DdaInformation } from './DdaInformation';
import { DdaDefinitionsUsers } from './DdaUsers/DdaUsers';
import { DdaDefinitionsIpLists } from './DdaIpLists/DdaIpLists';

export const DdaDefinitionsInformation: React.FC = () => {
  return (
    <React.Fragment>
      <DdaInformation />
      <DdaDefinitionsUsers />
      <DdaDefinitionsIpLists />
    </React.Fragment>
  );
};
