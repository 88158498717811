import * as React from 'react';
import { makeStyles, Typography } from "@bb-ui/react-library";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IconButton } from '@material-ui/core';
import { ChevronRight } from '@bb-ui/icons/dist/small';

const useStyles = makeStyles(() => ({
    button: {
      minWidth: 'auto',
      height: '1.5rem',
      padding: '0.2rem 0.5rem',
      fontSize: '0.75rem',
    },
}));

export interface ViewReleaseStageButtonProps {
    releaseStageID: string;
}

export const ViewReleaseStageButton: React.FunctionComponent<ViewReleaseStageButtonProps> = ({
    releaseStageID
}) => {
    const { t } = useTranslation();
    const history = useHistory();   

    return (
      <IconButton onClick={() => history.push(`/tenants/${releaseStageID}`)}>
        <Typography component="span" variant="inherit">
          {t('global.view')}
        </Typography>
        <ChevronRight data-testid="ChevronRightIcon" />
      </IconButton>
    );
};
