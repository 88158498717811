import React from 'react';
import { Card, CardContent, CardHeader, Theme } from '@bb-ui/react-library';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export interface DdaInformationItemProps {
  title: string | undefined;
  value?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: 'none',
      display: 'grid',
      gridSpacing: theme.spacing(2),
      gridTemplateColumns: '1fr 1fr',
      marginBottom: '1px',
    },
    cardContent: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.fontSizeSmall,
      textAlign: 'right',
    },
    cardHeader: {
      fontSize: theme.typography.fontSizeSmall,
      fontWeight: theme.typography.fontWeightBold,
    },
    unsetValue: {
      fontStyle: 'italic',
    },
  }),
);

export const DdaInformationItem: React.FC<DdaInformationItemProps> = (props) => {
  const { title, value } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader title={title} data-testid="dda-info-item-title" />
      <CardContent className={classes.cardContent} data-testid="dda-info-item-content">
        {value ? value : <span className={classes.unsetValue}>{t('ddaInformation.unset')}</span>}
      </CardContent>
    </Card>
  );
};
