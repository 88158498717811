import * as React from 'react';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useTranslation } from 'react-i18next';
import { Add } from '@bb-ui/icons/dist/small';
import { makeStyles } from '@bb-ui/react-library';
import CreateImageDialog from '../../dialogs/CreateImageDialog';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
}));

export interface AddImageButtonProps {
  onReload: () => void;
}

export const AddImageButton: React.FunctionComponent<AddImageButtonProps> = ({ onReload }) => {
  const { t } = useTranslation();
  const [createButtonDialogOpen, setCreateButtonDialogOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <PrimaryButton
        startIcon={<Add />}
        data-testid="add-button-images"
        onClick={() => setCreateButtonDialogOpen(true)}
        className={classes.button}
      >
        {t('images.addImageButton')}
      </PrimaryButton>
      <CreateImageDialog
        id="create-image-dialog"
        onClose={() => setCreateButtonDialogOpen(false)}
        onSuccess={onReload}
        open={createButtonDialogOpen}
      />
    </>
  );
};
