import { makeStyles, OutlineButton } from '@bb-ui/react-library';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@bb-ui/icons/dist/small';
import { EditReleaseStageDialog } from 'dialogs/EditReleaseStageDialog';
import { Item } from './ReleaseStages.types';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
}));

export interface EditReleaseStageButtonProps {
  onReload: () => void;
  releaseStage: Item;
}

export const EditReleaseStageButton: React.FunctionComponent<EditReleaseStageButtonProps> = ({
  onReload,
  releaseStage,
}) => {
  const { t } = useTranslation();
  const [editButtonDialogOpen, setEditButtonDialogOpen] = React.useState(false);
  const classes = useStyles();
  return (
    <>
      <OutlineButton
        startIcon={<Edit />}
        data-testid="edit-button-release-stage"
        onClick={() => setEditButtonDialogOpen(true)}
        className={classes.button}
      >
        {t('releaseStages.releaseStageEditButton')}
      </OutlineButton>
      <EditReleaseStageDialog
        id="edit-release-stage-dialog"
        onClose={() => setEditButtonDialogOpen(false)}
        onSuccess={onReload}
        open={editButtonDialogOpen}
        initialData={{
          releaseStage: releaseStage.releaseStage,
          learnVersion: releaseStage.learnVersion,
        }}
        releaseStageID={releaseStage.id}
      />
    </>
  );
};
