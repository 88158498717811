import * as React from 'react';
import {
  DefaultButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  PrimaryButton,
} from '@bb-ui/react-library';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';

export interface RemoveImagesDialogProps extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  amiId: string;
  version: string;
}

export const RemoveImagesDialog: React.FunctionComponent<RemoveImagesDialogProps> = (props) => {
  const { id, open, onClose, onSuccess, amiId, version } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doDelete,
    failedRequests,
    succeededRequests,
  } = useRestApi(apiUrl('lct', `registry/${amiId}`), { manual: true });

  function deleteImage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    doDelete(version, amiId);
  }

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('images.removeSuccess'), {
        variant: 'success',
      });
      clearSucceededRequests();
      onClose();
      onSuccess();
    }
  }, [clearSucceededRequests, onClose, enqueueSnackbar, onSuccess, succeededRequests.length, t]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('images.removeFailed', {
          message: failedRequests[0].error.message,
          amiId: amiId,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      onClose();
    }
  }, [
    clearFailedRequests,
    onClose,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    t,
    amiId,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={() => onClose()}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={() => onClose()} id={`${id}-title`}>
        {t('images.removeImageTitle')}
      </DialogTitle>
      <form onSubmit={deleteImage} noValidate data-testid="remove-image-form">
        <DialogContent>
          <DialogContentText id={`${id}-remove-image-text`} data-testid={`${id}-remove-image-text`}>
            {t('images.removeImageConfirmation', { amiId: amiId })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton type="submit">{t('global.yes')}</PrimaryButton>
          <DefaultButton onClick={() => onClose()}>{t('global.no')}</DefaultButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RemoveImagesDialog;
