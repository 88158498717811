// useTenantMobile.ts
import { useState, useEffect } from 'react';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useSnackbar } from 'hooks/useSnackbar';

export interface ConfigurationObject {
  configuration: Record<string, MobileConfigurationFields>;
}
export interface MobileProps {
  mobileprops?: MobileDetails;
  childData: ChildMobileDetails;
  childError: any;
  childLoading: boolean;
  updateMobileDetails: (newMobileDetails: MobileDetails, isModified: boolean) => void;
  updateChildDetails: (
    newMobileDetails: ChildMobileDetails,
    counter: number,
    newChild: boolean,
    deleteChild: boolean,
  ) => void;
}
export interface MobileConfigurationFields {
  environment: string;
  name: string;
  b2Url: string;
  registrationId?: string;
  publicId: string;
  tenantRegion: string;
  forceOff?: string;
  notes?: string;
  usernameLabel: string;
  authType: string;
  id: string;
  peopleSoftInstitutionId?: string;
}

export interface MobileDetails {
  tenantId: string;
  domain: string;
  configuration: MobileConfigurationFields;
}

export interface ChildMobileDetails {
  configuration: MobileConfigurationFields[];
}

interface UseTenantMobileProps {
  tenantId: string | undefined;
}

export const useTenantMobile = ({ tenantId }: UseTenantMobileProps) => {
  // API URLs
  const apiBaseUrlParentDetails = apiUrl(
    'config',
    `tenants/${tenantId}/capabilityConfigs/mobile-registration`,
  );
  const apiBaseUrlChildDetails = apiUrl(
    'config',
    `tenants/${tenantId}/capabilityConfigs/mobile-child-registrations`,
  );

  const { enqueueSnackbar } = useSnackbar();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  // Fetch Mobile and descendant Details
  const parentApi = useRestApi<MobileDetails>(apiBaseUrlParentDetails, { manual: true });
  const childApi = useRestApi<ChildMobileDetails>(apiBaseUrlChildDetails, { manual: true });
  const {
    data,
    error,
    loading,
    fetch,
    doPatch,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
  } = parentApi;

  const {
    data: childData,
    error: childError,
    loading: childLoading,
    fetch: fetchChild,
    doPatch: doPatchChild,
    doPut: doPutChild,
    succeededRequests: childSucceededRequests,
    failedRequests: childFailedRequests,
    clearSucceededRequests: clearChildSucceededRequests,
    clearFailedRequests: clearChildFailedRequests,
  } = childApi;

  // Fetch child data when parent data is successfully fetched
  useEffect(() => {
    if (data && !error) {
      fetchChild();
    }
  }, [data, error, fetchChild]);

  // Fetch parent data when tenantId changes
  useEffect(() => {
    if (tenantId) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      fetch().catch(() => {});
    }
  }, [fetch, tenantId]);

  // Handle successful parent requests
  useEffect(() => {
    if (succeededRequests.length > 0) {
      setIsSnackbarVisible(false);
      fetch();
      clearSucceededRequests();
    }
  }, [succeededRequests, clearSucceededRequests, fetch]);

  // Handle successful child requests
  useEffect(() => {
    if (childSucceededRequests.length > 0) {
      setIsSnackbarVisible(false);
      fetchChild();
      clearChildSucceededRequests();
    }
  }, [childSucceededRequests, clearChildSucceededRequests, fetchChild]);

  // Handle failed parent requests
  useEffect(() => {
    if (failedRequests.length > 0) {
      clearFailedRequests();
      if (!isSnackbarVisible) {
        enqueueSnackbar('Error updating configuration details. Try again.', { variant: 'error' });
        setIsSnackbarVisible(true);
      }
    }
  }, [failedRequests, clearFailedRequests, isSnackbarVisible, enqueueSnackbar]);

  // Handle failed child requests
  useEffect(() => {
    if (childFailedRequests.length > 0) {
      clearChildFailedRequests();
      if (!isSnackbarVisible) {
        enqueueSnackbar('Error updating configuration details. Try again.', { variant: 'error' });
        setIsSnackbarVisible(true);
      }
    }
  }, [childFailedRequests, clearChildFailedRequests, isSnackbarVisible, enqueueSnackbar]);

  return {
    doPatch,
    doPatchChild,
    doPutChild,
    data,
    error,
    loading,
    isSnackbarVisible,
    setIsSnackbarVisible,
    childData,
    childError,
    childLoading,
  };
};
