import * as React from 'react';
import {
  DefaultButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  PrimaryButton,
} from '@bb-ui/react-library';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';

export interface DeleteReleaseStageDialogProps extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  releaseStageID: string;
  releaseStageName: string;
}

export const DeleteReleaseStageDialog: React.FunctionComponent<DeleteReleaseStageDialogProps> = (
  props,
) => {
  const { id, open, onClose, onSuccess, releaseStageID, releaseStageName } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doDelete,
    failedRequests,
    succeededRequests,
  } = useRestApi(apiUrl('lct', 'releaseStage'), { manual: true });

  function deleteReleaseStage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    doDelete(releaseStageID);
  }

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('releaseStages.deleteReleaseStageDialog.succededMessage'), {
        variant: 'success',
      });
      clearSucceededRequests();
      onClose();
      onSuccess();
    }
  }, [clearSucceededRequests, onClose, enqueueSnackbar, onSuccess, succeededRequests.length, t]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('releaseStages.deleteReleaseStageDialog.editFailed', {
          message: failedRequests[0].error.message,
          ReleaseStage: releaseStageName,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      onClose();
    }
  }, [
    clearFailedRequests,
    onClose,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    t,
    releaseStageName,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={() => onClose()}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={() => onClose()} id={`${id}-title`}>
        {t('releaseStages.deleteReleaseStageDialog.title')}
      </DialogTitle>
      <form onSubmit={deleteReleaseStage} noValidate data-testid="delete-release-stage-form">
        <DialogContent>
          <DialogContentText
            id={`${id}-release-stage-delete-content-text`}
            data-testid={`${id}-release-stage-delete-content-text`}
          >
            {t('releaseStages.deleteReleaseStageDialog.content', {
              ReleaseStage: releaseStageName,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton type="submit">
            {t('releaseStages.deleteReleaseStageDialog.confirmDeleteReleaseStage')}
          </PrimaryButton>
          <DefaultButton onClick={() => onClose()}>
            {t('releaseStages.deleteReleaseStageDialog.cancelDeleteReleaseStage')}
          </DefaultButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteReleaseStageDialog;
